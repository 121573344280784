/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './productViewStyle';
import React, { useState, useEffect } from 'react';
import env from '../../../env';
import utils from '../../../utils';
import { ErrorPopup, Loading, Button, ErrorMessage, useGet, usePost, useRouter } from '../../../components';
import { ViewPartial } from '../../../cms/pages/partials/viewPartial';
//import { CommonProductBottom } from '../../../cms/pages/partials/commonProductBottom';
import { ProductTile } from '../productList/productTile';
import FsLightbox from 'fslightbox-react';
import { MetaTag } from '../../../components';
import { AddToCart } from './addToCart';
import Slider from "react-slick";
import { MdFavorite } from "react-icons/md";

//import { debounce } from '../../../lib/utils-core/debounce';
import { gtm } from '../../../lib/tracking';
//import { FacebookShareButton, FacebookIcon, PinterestShareButton, PinterestIcon } from "react-share";
import { createPortal } from "react-dom";
import { getEnquiryFormModel } from './enquiryFormModel';
import { useForm } from '../../../form';
import { ProductPagePartial } from '../../../cms/pages/partials/productPagePartial';
import { Fragment } from 'react';

function Portal({ children }) {
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function ProductView(props) {

  const { query } = useRouter();
  const [loaded, setLoaded] = React.useState(false);
  const get = useGet();
  const productCode = query.id || '';
  const category = query.category || '';
  const slug = query.slug || '';
  //console.log(category, slug, productCode)
  React.useEffect(() => {
    console.log('productview use effect');
    //const url = productCode ? `/api/product/getProduct/${productCode}` : `/api/product/getProductByUrl?category=${category}&slug=${slug}`
    const url = `/api/product/getProduct/${slug}`;
    get.send(env.apiBase + url);
    setLoaded(false);
    // eslint-disable-next-line
  }, [productCode, slug]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;

  if (get.done() && !loaded) {
    setLoaded(true);
    const pageUrl = productCode ? `/product/${productCode}` : `/products/${category}/${slug}`;
    const pageTitle = data.totalCnt === 0 ? 'No product found' : data.productName;
    gtm.pageView(pageUrl, pageTitle);
  }

  if (data.totalCnt === 0) return <div>No product found.</div>
  return (
    <React.Fragment>
      {/* <div className="single-banner banner-item bannerSize--thin"
        style={{ backgroundImage: utils.css.bgUrlStyle(utils.site.resourcePath('/sites/beehive-react/media/banners/adobestock_221175640.jpg')) }}>
      </div> */}
      <Product product={data} />
      {/* <CommonProductBottom /> */}
      <ProductPagePartial />
      <ViewPartial />
    </React.Fragment>
  );
}

function Product({ product }) {

  const bgUrl = "/sites/tallulahandkai/media/banners/DSC04442.jpg";

  //product.productImages = [];
  // const mainPhoto = product.productImages.length > 0 ? product.productImages[0] : { imageUrl: '/assets/product-empty.png', alt: product.productName };
  //const mainPhoto = {imageUrl: utils.site.resourcePath('/sites/beehive-react/media/products/coastal-flora-1k-hexjar.jpg'), alt: product.productName};

  const mainPhoto = product.productImages.length > 0 ?
    { imageUrl: product.productImages[0].imageUrl, alt: product.productImages[0].alt ? product.productImages[0].alt : product.productName } :
    { imageUrl: '/assets/logos/logo.png', alt: product.productName };

  const allPhotos = product.productImages || [];
  //console.log("aaa", allPhotos);
  //const otherPhotos = product.productImages.length > 0 ? product.productImages.filter(x => !x.isPackage) : [];
  const popupImages = allPhotos.map(x => x.imageUrl);

  const [heroImage, setHeroImage] = React.useState(mainPhoto);
  const [openPopup, setOpenPopup] = useState(false);

  const [toggler, setToggler] = React.useState(false);
  //const [popupImages, setPopupImages] = React.useState();
  const [popupIndex, setPopupIndex] = React.useState(0);

  //const shareUrl = utils.site.resourcePath('/product/' + product.productSlug);
  //const heroImageUrl = utils.site.resourcePath(mainPhoto.imageUrl);

  const enquiryFormModel = getEnquiryFormModel({});
  const enquiryForm = useForm(enquiryFormModel, { usePlaceholder: true });

  const enquiryFormRender = (name) => enquiryForm.renderControl(name, null);
  const post = usePost();
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [favorite, setFavorite] = React.useState(() => {
    if (localStorage.favorites.includes(product.productId)) {
      return true;
    }
    return false;
  });

  //const breadCrumbs = product.breadCrumbs;

  //console.log('favorite', favorite);
  //const [isMobile, setIsMobile] = React.useState(utils.site.isMobile())

  // React.useEffect(() => {
  //   const resized = debounce(() => {
  //     setIsMobile(utils.site.isMobile())
  //   }, 1000)
  //   if (typeof window !== `undefined`) {
  //     window.addEventListener('resize', resized)
  //   }
  //   return () => {
  //     if (typeof window !== `undefined`) {
  //       window.removeEventListener('resize', resized)
  //     }
  //   }

  // })


  function openPopupImage(imageUrl) {
    const inx = utils.array.findIndex(popupImages, x => x === imageUrl);
    setPopupIndex(inx >= 0 ? inx : 0)
    setToggler(!toggler)
  }

  var settings = {
    dots: false,
    infinite: allPhotos.length > 2 ? true : false,
    speed: 1500,
    autoplaySpeed: 3000,
    //fade: true,
    autoplay: false,
    slidesToShow: allPhotos.length >= 3 ? 3 : allPhotos.length,
    slidesToScroll: 1
  };

  const slides = allPhotos.map((photo, index) => <div css={style.productViewImage} key={index}
    className={`productView__image ${heroImage.imageUrl === photo.imageUrl ? 'current' : ''}`}>
    <div css={style.productViewImageBg} className={`productView__imageBg`}
      style={{
        backgroundImage: utils.css.bgUrlStyle(photo.imageUrl)
      }}
      onClick={() =>
        setHeroImage(photo)}
    >
    </div>
    {/* <img src={utils.site.resourcePath(photo.imageUrl)} alt={photo.alt} onClick={() => setHeroImage(photo)} /> */}
  </div>
  );

  function onSubmit(e) {
    enquiryForm.validateForm(e, () => {
      const value = { ...enquiryForm.getValue(), productName: product.productName, emailReceiver: '' };
      //var files = form.getFiles();
      var formData = new FormData();
      formData.append('jsonData', JSON.stringify(value));
      //post.send(env.apiBase + "/api/contact/send", value);
      post.sendFormData(env.apiBase + "/api/cart/sendenquire", formData);
      setSendingStatus('pending');
    })
    //console.log('submitting');
  }

  if (post.done() && sendingStatus === 'pending') {
    //var value = enquiryForm.getValue();
    //gtm.sendEvent('contactFormSubmitted', {contactEmail: value.email} )
    enquiryForm.reset(enquiryFormModel);
    setSendingStatus(null);
    setTimeout(() => {
      setOpenPopup(false);
      document.body.style.overflow = "unset";
    }, 1500);
  }

  return (
    <Fragment>
      <div className="cms_item single-banner banner-item bannerSize--thin" style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl), backgroundPositionY: '50%' }}></div>
      <div className="contentPanel productViewPanel">
        <Portal>
          {
            openPopup &&
            <div className="product__enquiry__form__container" css={style.enquiryFormContainer}>
              <form className="contactForm">
                <div className="closeButton" onClick={() => {
                  setOpenPopup(false);
                  document.body.style.overflow = "unset";
                }}>
                  CLOSE
                </div>
                <div className="enquireNowText">
                  Enquire Now
                </div>
                <div className="form-row">
                  <div className="col-md-4 mb-2">{enquiryFormRender('name')}</div>
                  <div className="col-md-4 mb-2">{enquiryFormRender('phone')}</div>
                  <div className="col-md-4 mb-2">{enquiryFormRender('email')}</div>
                </div>
                <div className="form-row">
                  <div className="col-md-12 mb-2">{enquiryFormRender('enquiry')}</div>
                </div>

                <div className="form-row">
                  <div className="col-md-12 mb-2">
                    <div className="actions">
                      {/* onClick={onSubmit} status={post.status} */}
                      <Button onClick={onSubmit} status={post.status} >Send Message</Button>
                      <ErrorMessage errors={post.errors} />
                      {post.status === 'done' && 'Successfully sent!'}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }
        </Portal>

        <FsLightbox
          toggler={toggler}
          sources={popupImages}
          sourceIndex={popupIndex}
        />
        <MetaTag data={getMetaTag(product, mainPhoto)} />

        <div className="contentPanel__bg">
        </div>
        <div className="container">
          {/* <Breadcrumb breadcrumb={product.breadcrumb} /> */}
          <div className="productView">
            <div itemScope={true} itemType="http://schema.org/Product">
              <meta itemProp="name" content={product.productName} />
              <div itemProp="brand" itemType="http://schema.org/Brand" itemScope>
                <meta itemProp="name" content={env.siteName} />
              </div>

              {product.description && <meta itemProp="description" content={product.description} />}
              {/* {barcodeCleaned && <meta itemProp="sku" content={barcodeCleaned} />} */}
              {heroImage && heroImage.imageUrl && <link itemProp="image" href={env.baseOrigin + heroImage.imageUrl} />}

              <div className="row">

                <div className="col-md-10" css={style.productPageCol}>
                  {/* {breadCrumbs.map((item, index) => {

              })} */}
                  {/* <ul className="breadCrumbs" css={style.productBreadCrumbs}>
                    <li> <SiteLink to="/"> Home </SiteLink> </li>
                    <li> <SiteLink to="/shop"> Shop </SiteLink> </li>
                    {
                      breadCrumbs.map((item, index) =>
                        <li key={index} className={`breadCrumb ${index === (breadCrumbs.length - 1) ? 'last-item' : ''}`}>
                          <SiteLink to={item.categoryPageUrl}> {item.categoryPageTitle} </SiteLink>
                        </li>
                      )
                    }
                    <li>{product.productName}</li>
                  </ul> */}

                </div>

                <div className="col-md-2" css={style.productPageCol} style={{ textAlign: 'right' }}>
                  <MdFavorite css={style.productFavorite} className={`${favorite === true ? 'checked' : ''}`} onClick={() => {
                    setFavorite(pre => {
                      let _localStorageFavorites = localStorage.favorites.replace(product.productId + "|", "");
                      let _new = !pre;
                      if (_new === true) {
                        _localStorageFavorites = _localStorageFavorites + product.productId + "|";
                      }
                      localStorage.setItem("favorites", _localStorageFavorites);
                      return _new;
                    });
                  }}></MdFavorite>
                </div>

                <div className="col-md-6 col-12" css={style.productPageCol}>
                  <div css={style.heroImageContainer} className="productView__hero__container">
                    <img css={style.heroImage} className="productView__hero" src={heroImage.imageUrl} alt={heroImage.alt} onClick={() => openPopupImage(heroImage.imageUrl)} />
                  </div>

                  {
                    (allPhotos && allPhotos.length > 1) &&
                    <div css={style.productViewImages} className="productView__images">
                      {/* {slides} */}
                      <Slider {...settings} >
                        {slides}
                      </Slider>
                    </div>
                  }

                  {/* <div className="desk__top" css={style.desktopMobile}>
                  <div className="quote__container" css={style.quoteContainer}>
                    <div className="quote__text">
                      Like this product but doesn't match your specifications? Click for a custom quote.
                    </div>
                    <Button onClick={() => {
                      setOpenPopup(true);
                      document.body.style.overflow = "hidden";
                    }}> CUSTOM QUOTE </Button>
                  </div>

                  <div className="social-media" css={style.socialMedia}>
                    <div className="social-icon-text"> SHARE THIS PRODUCT:</div>
                    <FacebookShareButton url={shareUrl} title="Sharing with Facebook">
                      <FacebookIcon size={50} round={true} />
                    </FacebookShareButton>
                    <PinterestShareButton url={shareUrl} media={heroImageUrl} title="Sharing with Pinterest" style={{ marginLeft: '10px' }}>
                      <PinterestIcon size={50} round={true} />
                    </PinterestShareButton>
                  </div>
                </div> */}

                </div>
                <div className="col-md-6 col-12" css={style.productPageCol}>

                  <h1 className="productView__title" style={{ textTransform: 'uppercase' }} css={style.productViewTitle}>
                    <strong>{product.productName}</strong>
                  </h1>

                  <div className="productView__shortDes" css={style.productViewShortDes}>
                    {product.shortDesc}
                  </div>

                  <div css={style.desc} dangerouslySetInnerHTML={{ __html: product.description }}></div>

                  {/* <hr/> */}

                  {/* <div className="productView__productCode">
                  Product code: <b>{product.productCode}</b>
                </div> */}

                  {
                    product.productStatus === 0 && <AddToCart product={product} />
                  }

                  {
                    product.productStatus === 100 &&
                    <Button onClick={() => {
                      setOpenPopup(true);
                      document.body.style.overflow = "hidden";
                    }}> ENQUIRE NOW </Button>
                  }

                  {
                    product.productStatus === 1 &&
                    <Button disabled> SOLD OUT </Button>
                  }

                  {
                    product.productStatus === 98 &&
                    <Button disabled> NOT AVAILABLE </Button>
                  }

                  {/* <div className="mobile" css={style.desktopMobile}>
                  <div className="quote__container" css={style.quoteContainer}>
                    <div className="quote__text">
                      Like this product but doesn't match your specifications? Click for a custom quote.
                    </div>
                    <Button onClick={() => {
                      setOpenPopup(true);
                      document.body.style.overflow = "hidden";
                    }}> CUSTOM QUOTE </Button>
                  </div>

                  <div className="social-media" css={style.socialMedia}>
                    <div className="social-icon-text"> SHARE THIS PRODUCT:</div>
                    <FacebookShareButton url={shareUrl} title="Sharing with Facebook">
                      <FacebookIcon size={50} round={true} />
                    </FacebookShareButton>
                    <PinterestShareButton url={shareUrl} title="Sharing with Pinterest" style={{ marginLeft: '10px' }}>
                      <PinterestIcon size={50} round={true} />
                    </PinterestShareButton>
                  </div>
                </div> */}

                </div>
              </div>
              <RelatedProducts products={product.relatedProducts} />
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  )
}

function getMetaTag(product, mainPhoto) {
  return {
    title: product.productName,
    description: product.metaDesc || product.intro,
    keywords: '',
    heroImageUrl: utils.site.resourcePath(mainPhoto.imageUrl),
    language: 'English',
    country: 'Australia',
    siteName: env.siteName,
    url: utils.site.resourcePath('/product/' + product.productSlug),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  }
}

function RelatedProducts({ products }) {
  //console.log(products)
  if (!products || products.length === 0) return null;
  return <div className="productView__relatedProducts">
    <h3>RELATED PRODUCTS</h3>
    <div className="productList">
      {products.map((product, index) =>
        <ProductTile product={product} key={product.productCode}>
        </ProductTile>
      )}
    </div>
  </div>
}