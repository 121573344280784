import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles: css(mq({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden',
        //border:'1px solid red'
        marginLeft: ['-10px', null, null, null, '-2rem', '-5.5rem'],
        marginRight: ['-10px', null, null, null, '-2rem', '-5.5rem']
    })),
    pageTile__tile: css(mq({
        // flex:['0 0 1000%',null,'0 0 88%'],
        // maxWidth:['100%',null,'88%'],
        flex: ['0 0 1000%', null, '0 0 100%'],
        maxWidth: ['100%', null, '100%'],
        padding: '10px',
        paddingTop: '3rem',
        zIndex: 1,
        //marginLeft: 'auto',

        '&:nth-of-type(even)': {
            marginLeft: 'auto',
            '.pageTile__bsyc__bg': mq({
                order: ['0', null, '2'],
            }),
            '.pageTile__bsyc__content__details': mq({
                order: '1'
            })
        },
        '&:nth-of-type(1)': {
            paddingTop: '10px'
        }
    })),

    tile__content: css({
        '&:hover': {
            textDecoration: 'none',
            '& .pageTile__bsyc__content__details': {
                //color: 'white',
                backgroundColor: variables.colorDarkGold,
                '& .pageTile__bsyc__title': {
                    color: variables.colorCream
                },
                '& .pageTile__bsyc__desc': {
                    color: variables.colorCream
                },
                '& .pageTile__bsyc__dot': {
                    borderTopColor: variables.colorCream
                }

                // '&.default' : css({
                //      backgroundColor: 'rgba(0,113,188,1)'
                // }),
                // '&.red' : css({
                //      backgroundColor: 'rgba(211,61,2,1)'
                // }),
                // '&.green' : css({
                //      backgroundColor: 'rgba(0, 146, 69,1)'
                // })
            },
            '& .pageTile__bsyc__bg2': {
                //transform: scale(1.05)
                //transform : 'scale(1.05)'
            }
        }
    }),

    tile__flip__wrapper: css(mq({
        zIndex: '-1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: ['center', null, 'space-between'],
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        flexWrap: 'wrap',
        alignItems: 'center'
    })),

    tile__bg: css(mq({
        //height: '0',
        //paddingTop: ['50%','35%','45%'],
        //height: ['284px',null,null,'448px'],
        height: ['230px', null, null, '490px'],
        flex: '1 1 300px',
        overflow: 'hidden',
        position: 'relative',
        boxShadow: '3px 5px 10px rgba(0,0,0,0.3)'
    })),

    tile__bg2: css(mq({
        height: '100%',
        //backgroundPosition: '50%',
        //backgroundRepeat: 'no-repeat',
        //backgroundSize: 'cover',
        overflow: 'hidden',
        //transition:'all .2s',
        position: 'absolute',
        top: '0px',
        width: '100%',
        '.slick-next, .slick-prev': {
            display: 'none !important',
        },
        // '&.even' : mq({
        //     '.slick-next' : mq({
        //         right: '70px',
        //         display:'none',
        //     })
        // }),
        // '&.odd' : mq({
        //     '.slick-prev' : mq({
        //         left: '70px',
        //         display:'none',
        //     })
        // })
    })),

    tile__bg2__inner: css(mq({
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        //paddingTop:['70%', '65%', '60%']
        height: ['232px', null, null, '492px']
    })),

    tile__details: css(mq({
        overflow: 'hidden',
        flexGrow: '1',
        textAlign: 'center',
        width: '100%',
        bottom: '0',
        backgroundColor: variables.colorGreen,
        zIndex : 1,

        //  '&.orange' : css({
        //     backgroundColor: variables.orangeColor
        //  }),

        //  '&.blie' : css({
        //     backgroundColor: variables.blieColor
        //  }),

        //  '&.green' : css({
        //     backgroundColor: variables.greenColor
        //  }),

        //  '&.yellow ' : css({
        //     backgroundColor: variables.yellowColor
        //  }),



        flex: ['1 1 300px', null, '1 1 400px'],
        maxWidth: ['300px', null, '400px'],

        height: ['unset', null, '75%'],
        transition: 'all .2s ease-in-out',
        //color: variables.fontColor,
        position: 'relative',
        marginTop: ['-20px', null, '0px'],
        boxShadow: '3px 5px 10px rgba(0,0,0,0.3)',
        //marginLeft: ['0px',null,'-50px'],
        '&.even': mq({
            marginLeft: ['0px', null, '-50px'],
            marginRight: 'unset'
        }),
        '&.odd': mq({
            marginRight: ['0px', null, '-50px'],
            marginLeft: 'unset'
        }),
        '& .pageTile__bsyc__container': mq({
            //position: 'absolute',
            position: ['static,', null, 'absolute'],
            top: '50%',
            left: '50%',
            transform: ['none', null, 'translate(-50%, -50%)'],
            width: ['100%', null, '76%'],
            padding: ['1rem', null, '0px'],
            '& .pageTile__bsyc__title': {
                //fontFamily: 'proxima-soft, sans-serif',
                fontSize: '1.2rem',
                textTransform: 'uppercase',
                fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                //paddingTop: '.5rem',
                paddingBottom: '.5rem',
                color: variables.colorCream
            },
            '& .pageTile__bsyc__dot': {
                //borderTopStyle: 'dashed',
                width: '67%',
                marginTop: '0px',
                marginBottom: '0px',
                paddingBottom: '0.5rem',
                borderTopColor: variables.colorCream
            },
            '& .pageTile__bsyc__desc': {
                lineHeight: '1.4',
                fontSize: '0.9rem',
                //color: '#3d3d3d',
                minHeight: '70px',
                color: variables.colorCream
            }
        })
    })
    )
}