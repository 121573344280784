import { css } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: variables.colorDarkGray,
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    height: [44, null, 56],
  })),
  logo: css(mq({})),
  logoImg: css(mq({
    display: 'block',
    height: [90, null, 140],
    marginTop: ['5rem', null, '6rem'],
    transition : 'all 0.3s'
  })),
  logoScrolled: css(mq({
    'img': mq({
      height: [40, null, 46],
      marginTop: "0rem",
    })
  })),
}