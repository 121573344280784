const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorBlue : '#007bff',
  colorDarkGray : '#1a1517',
  colorGreen : '#608d7c',
  colorDarkGold : '#a17c42',
  colorAqua : '#628d7d',
  colorCream : '#e5decb'
}

const variables = {
  familyBase: '"roboto-condensed",sans-serif',
  familyHeader: '"sacre-bleu-mvb",serif',

  primaryColor: colors.colorGreen,
  fontColor: colors.colorCream,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;